

import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from "@/lib/util";
import AbpBase from '@/lib/abpbase'
import PageRequest from "@/store/entities/page-request";
import PeopleFind from "../../../components/People/people-find.vue";
import Campana from "../../../store/entities/OZONE/campana"; //Cambiar por Maquina

class PageFranqueoRequest extends PageRequest {
  numCliente: string;
  oferta: string;
  producto: string;
  contrato: string;
}

@Component({components: {PeopleFind}})
export default class FranqueoHistorico extends AbpBase{
    pagerequest: PageFranqueoRequest = new PageFranqueoRequest();
    findModalShow: boolean = false;
    currentSearch: { key: string; value: string; label: string }[] = [];
    campana: Campana = null; //Cambiar por Maquina


    // get listHistorico(){
    //     return this.$store.state.franqueo.listHistorico;
    // }

    // get loading() {
    //     return this.$store.state.franqueo.loading
    // }

    async created(){
        this.getPage();
    }

    async getPage(){
        // await this.$store.dispatch({
        //     type: 'franqueo/getFranqueoHistorico'
        // })
    }

    async hide() {
        this.findModalShow = false;
    }
    async find() {
        this.findModalShow = true;
    }

    editRow(item, index, button) {
        this.$store.commit("grupo/edit", item);
        //this.edit();
    }

    getSearch = async (model) => {
    
        this.currentSearch.length = 0;

        model.forEach((element) => {
        if (element.value) {
            this.pagerequest[element.fieldBind] = element.value;
            this.currentSearch.push({
            label: element.fieldName,
            key: element.fieldBind,
            value:
                element.fieldType === "custom"
                ? element.value[element.display]
                : element.value,
            });
        } else {
            delete this.pagerequest[element.fieldBind];
        }
        });
        console.log(this.currentSearch);
        await this.getPage();
        this.findModalShow = false;
    };

    columns = [
        {
            title: this.L('Fecha depósito'),
            dataIndex: '',
            thClass: 'w-auto',
            tdClass: 'w-auto'
        },
        {
            title: this.L('Num. Cliente Op.'),
            dataIndex: 'nclienteop'
        },
        {
            title: this.L('Contrato Op.'),
            dataIndex: 'contrato'
        },
        {
            title: this.L('Oferta'),
            dataIndex: 'oferta'
        },
        {
            title: this.L('Producto'),
            dataIndex: 'producto'
        },
        {
            title: this.L('Num. Envios'),
            dataIndex: 'nenvios'
        },
        {
            title: this.L('Ámbito'),
            dataIndex: 'ambito'
        },
        {
            title: this.L('Tramo'),
            dataIndex: 'tramo'
        },
        {
            title: this.L('Cont. Envios Inicial'),
            dataIndex: 'contenviosinit'
        },
        {
            title: this.L('Cont. Envios Final'),
            dataIndex: 'contenviosfin'
        },
        {
            scopedSlots: { customRender: "actions" },
            title: "Acciones",
            fixed: "right"
        }
    ];

    searchDefinition: {}[] = [
    {
      fieldName: "Tipo Franqueo",
      fieldType: "custom",
      display: "nombre",
      custom: "select-tipo-franqueo",
      fieldBind: "tipoFranqueo",
      value: this.campana,
    },
    {
      fieldName: "Num. Cliente",
      fieldType: "text",
      fieldBind: "numCliente",
      value: "",
    },
    {
      fieldName: "Oferta",
      fieldType: "text",
      fieldBind: "oferta",
      value: "",
    },
    {
      fieldName: "Producto",
      fieldType: "text",
      fieldBind: "producto",
      value: "",
    },
    {
      fieldName: "Contrato",
      fieldType: "text",
      fieldBind: "contrato",
      value: "",
    },
    {
      fieldName: "Máquina",
      fieldType: "custom",
      display: "nombre",
      custom: "select-maquina",
      fieldBind: "Maquina",
      value: this.campana, //Cambiar por Maquina
    },{
      fieldName: "Fecha Inicio",
      fieldType: "date",
      fieldBind: "fechaInicio",
      value: "",
    },
    {
      fieldName: "Fecha Fin",
      fieldType: "date",
      fieldBind: "fechaFin",
      value: "",
    },
  ];
}

